<template> 
  <div class="container size-mobile bg-element-white p-0 min-height-second"
    style="margin-top:0px;min-height: calc(100vh);"> 

    <!-- Spinner -->
    <div id="overlay" v-if="progressLogout">
        <span class="d-flex justify-content-center align-items-center" style="height: -webkit-fill-available;color:white">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div> Sedang Logout...
        </span>
    </div>
    <div class="banner-home">
        <div class="container p-3 d-flex flex-column">
            <span class="text-1">MITRA MULTI USAHA INSANI</span>
            <div class="d-flex justify-content-between align-items-end mt-3">
                <span class="text-6">Hai, {{name}} ! <br>Selamat Bekerja <br> <b>{{nama_tempat_kerja}}</b></span>
                <img src="../assets/img/interview.png" alt="Ibunda" class="icon-logo"
                    style="max-width: 80px !important; ">
            </div>
        </div>
    </div> 
    
    <div class="container p-3 d-flex flex-column">
        <div class="title-menu">
            <span class="text-4">Pekerjaan</span>
            <div class="d-flex justify-content-between mt-3">
                <router-link class="link" to="/absensi">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/laporan-kerja.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Absensi</span>
                    </div>
                </router-link>
                <router-link class="link" to="/paket">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/paket.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Paket</span>
                    </div>
                </router-link>
                <router-link class="link" to="/tamu">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/tamu.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Tamu</span>
                    </div>
                </router-link>
                <router-link class="link" to="/kendaraan">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/Mobil-Dinas-2.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Kendaraan Dinas</span>
                    </div>
                </router-link> 
            </div>
        </div>
        <div class="title-menu"> 
            <div class="d-flex justify-content-between"> 
                <router-link class="link" to="/cek-lokasi-old">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/maps.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Patroli</span>
                    </div>
                </router-link>
                <router-link class="link" to="/cek-lokasi">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/maps.png">
                            <img src="../assets/img/new.png" style="width:80px;position:absolute;">
                        </div>
                        <span class="text-center menu-title">Patroli (NEW)</span>
                    </div>
                </router-link> 
                <router-link class="link" to="/ketidak-sesuaian">
                    <div class="d-flex flex-column align-items-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/warning.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Ketidak Sesuaian</span>
                    </div>
                </router-link> 
                <!-- visibility hidden -->
                <!-- <div class="d-flex flex-column justify-content-center" style="visibility: hidden;">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                    </div>
                    <span class="text-center menu-title"> </span>
                </div>   -->
                <div class="d-flex flex-column justify-content-center" style="visibility: hidden;">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                    </div>
                    <span class="text-center menu-title"> </span>
                </div> 
            </div>
        </div>
    </div>
 
    <div class="container p-3 d-flex flex-column">
        <div class="title-menu">
            <span class="text-4">Pengajuan</span>
            <div class="d-flex justify-content-between mt-3">
                <router-link class="link" to="/cuti">
                    <div class="d-flex flex-column justify-content-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/cuti.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Cuti</span>
                    </div>
                </router-link>
                <router-link class="link" to="/izin">
                <div class="d-flex flex-column justify-content-center">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                        <img src="../assets/img/izin.png" alt="Ibunda">
                    </div>
                    <span class="text-center menu-title">Izin</span>
                </div>
                </router-link>
                <router-link class="link" to="/lembur">
                <div class="d-flex flex-column justify-content-center">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                        <img src="../assets/img/sakit.png" alt="Ibunda">
                    </div>
                    <span class="text-center menu-title">Lembur</span>
                </div>
                </router-link>
                <div class="d-flex flex-column justify-content-center" style="visibility: hidden;">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                    </div>
                    <span class="text-center menu-title">Log Out</span>
                </div>  
                <!-- <div class="d-flex flex-column justify-content-center">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                        <img src="../assets/img/laporan.png" alt="Ibunda">
                    </div>
                    <span class="text-center menu-title">Laporan</span>
                </div>  -->
            </div>
        </div> 
    </div> 
    <div class="container p-3 d-flex flex-column">
        <div class="title-menu">
            <span class="text-4">Lainnya</span>
            <div class="d-flex justify-content-between mt-3">
                <router-link class="link" to="/profil">
                    <div class="d-flex flex-column justify-content-center">
                        <div class="menu-wrapper d-flex justify-content-center align-items-center">
                            <img src="../assets/img/satpamicon.png" alt="Ibunda">
                        </div>
                        <span class="text-center menu-title">Profil</span>
                    </div>
                </router-link>

                <div class="d-flex flex-column justify-content-center" @click="logout" style="cursor:pointer !important">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                        <img src="../assets/img/logout.png" alt="Ibunda">
                    </div>
                    <span class="text-center menu-title">Log Out</span>
                </div>  

                <!-- visibility hidden -->
                <div class="d-flex flex-column justify-content-center" style="visibility: hidden;">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                    </div>
                    <span class="text-center menu-title">Log Out</span>
                </div>  
                <div class="d-flex flex-column justify-content-center" style="visibility: hidden;">
                    <div class="menu-wrapper d-flex justify-content-center align-items-center">
                    </div>
                    <span class="text-center menu-title">Log Out</span>
                </div>  
            </div>
        </div> 
    </div>
    <div class="text-center" style="position:absolute;bottom:10px;height:20px;width:100%;">
        Powered By <b>JNIUS</b>
    </div>
</div>
</template>

<script> 
import Api from '../Api'
export default {
  name: "home",
    data(){ 
        const cred = JSON.parse(localStorage.cred)
        
        return { 
            myData: '',
            progressLogout:false,
            isLoading: false,
            name: cred ? cred.name : '',
            nama_tempat_kerja: cred ? cred.nama_tempat_kerja: '',
            progressBar: 0, //VARIABLE INI NILAINYA AKAN BERUBAH SESUAI PROGRESS UPLOADING
            message: '',  
        }
    },
    mounted(){
        this.load()
        },
    created() {
        // this.getAllFile()
    },  
    methods:{
        async load() {
         
            await Api.get("auth/me",{
                headers: {Authorization: 'Bearer ' + JSON.parse(localStorage.token)}
            })
            .then((response) => {
                // console.log(response) 
                    this.myData = JSON.parse(localStorage.cred)
                    this.myData.id_tempat_kerja= response.data.data.workplace_id
                    this.myData.nama_tempat_kerja= response.data.data.nama_tempat_kerja
                localStorage.setItem('cred', JSON.stringify(this.myData))
            }).catch((error) => {
                console.log(error);
                // this.$refs.ae.showAlert()
            });
        }, 
        logout(){
            this.progressLogout = true
            setTimeout(()=>{
                this.progressLogout = false
                // this.yourMethod()
                localStorage.clear();
                this.$router.replace('/login')
            },1200);
            
            // this.progressLogout = false
            
        }, 
        //RESET FORM UPLOAD
        reset() {
            this.$refs.file.value = '';
        }
    }

};
</script>

